.offerPage {
    position: relative;
    display: flex;
    flex-direction: column;
}

.offerPage__header {
    margin-top: 5vw;
    height: 15vw;
    width: 100%;
    overflow: hidden;
    background-color: white;
}

@media (max-width: 1400px) {
    .offerPage__header {
        height: 12rem;
    }
}

@media (max-width: 1200px) {
    .offerPage__header {
        height: 11rem;
    }
}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .offerPage__header {
        height: 9rem;
    }
}


