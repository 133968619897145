.aboutUsPage__whyUs {
    position: relative;
    margin: 2vw 5vw;
    overflow: hidden;
    display: flex;
}

.aboutUsPage__desc {
    text-align: center;
    padding: 0 5vw;
    font-size: 1.2vw;
    line-height: 1.5vw;
    transform: translateX(-150%);
    transition: 2s;
}

.aboutUsPage__desc-show {
    transform: translateX(0);
}


.aboutUsPage__whyUs-box {
    flex-direction: column;
    flex-basis: 25%;
    padding: 1vw 1vw;
    text-align: justify;
    transform: translateY(150%);
    transition: 1.5s;
}

.aboutUsPage__whyUs-box:nth-of-type(2) {
    transition-delay: .3s;
}

.aboutUsPage__whyUs-box:nth-of-type(3) {
    transition-delay: .6s;
}

.aboutUsPage__whyUs-box:nth-of-type(4) {
    transition-delay: .9s;
}

.aboutUsPage__whyUs-box-show {
    transform: translateY(0);
}

.aboutUsPage__whyUs-box-icon {
    font-size: 3vw;
    margin-bottom: 1vw;
    width: 100%;
    text-align: center;
}

.aboutUsPage__whyUs-box-img {
    display: block;
    height: 8vw;
    margin: 0 auto;
    padding: 1vw;
}

.aboutUsPage__whyUs-box-header {
    font-size: 1.5vw;
    height: 3vw;
    text-align: center;
}

.aboutUsPage__whyUs-box-text, .aboutUsPage__whyUs-box-text--project {
    text-align: center;
    font-size: 1vw;
    line-height: 1.5vw;
    height: 8vw;
    margin-top: 1vw;
}

.aboutUsPage__whyUs-box-line {
    border-bottom: 1px solid black;
    margin-right: 12vw;
}

@media (max-width: 1400px) {
    .aboutUsPage__whyUs-box {
        margin-top: 20px;
    }

    .aboutUsPage__desc {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    .aboutUsPage__whyUs-box-icon {
        font-size: 2.8rem;
        margin-bottom: .6rem;
    }

    .aboutUsPage__whyUs-box-header {
        font-size: 1.1rem;
        height: 2.2rem;
    }

    .aboutUsPage__whyUs-box-text {
        font-size: .9rem;
        line-height: 1.4rem;
        height: 9rem;
    }

    .aboutUsPage__whyUs-box-text--project{
        height: 11rem;
    }

    .aboutUsPage__whyUs-box-img {
        height: 6rem;
    }
}

@media (max-width: 1200px) {
    .aboutUsPage__whyUs-box-text {
        font-size: 1rem;
        height: 10rem;
    }

    .aboutUsPage__whyUs-box-text--project{
        height: 15rem;
    }

    .aboutUsPage__whyUs-box-img {
        height: 5.5rem;
    }
}

@media (max-width: 992px) {
    .aboutUsPage__whyUs {
        flex-wrap: wrap;
    }

    .aboutUsPage__whyUs-box {
        flex-basis: 50%;
    }

    .aboutUsPage__whyUs-box-text {
        font-size: 1rem;
        height: 7rem;
    }
}

@media (max-width: 768px) {
    .aboutUsPage__desc {
        padding: 0 4vw;
        font-size: .9rem;
        line-height: 1.3rem;
    }

    .aboutUsPage__whyUs-box-icon {
        font-size: 2.4rem;
    }

    .aboutUsPage__whyUs-box-header {
        font-size: 1.2rem;
    }

    .aboutUsPage__whyUs-box-text {
        font-size: .9rem;
        height: 8rem;
    }

    .aboutUsPage__whyUs-box-img {
        height: 5rem;
        margin: .5rem auto 0;
    }

    .aboutUsPage__whyUs-box-text--project{
        height: 9.5rem;
    }
}

@media (max-width: 576px) {
    .aboutUsPage__desc {
        padding: 0 2vw;
        font-size: .8rem;
        line-height: 1.1rem;
    }

    .aboutUsPage__whyUs-box {
        flex-basis: 100%;
    }

    .aboutUsPage__whyUs-box-icon {
        font-size: 2rem;
    }

    .aboutUsPage__whyUs-box-header {
        font-size: 1rem;
    }

    .aboutUsPage__whyUs-box-text {
        font-size: .9rem;
        height: 6rem;
    }

    .aboutUsPage__whyUs-box-line {
        margin-right: 10rem;
    }

    .aboutUsPage__whyUs-box-text--project{
        height: 7rem;
    }
}