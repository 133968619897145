.aboutUsPage {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.aboutUsPage__header {
    margin-top: 8vw;
    width: 100%;
    overflow: hidden;
    background-color: white;
}

.aboutUsPage__company {
    display: flex;
    position: relative;
}

.aboutUsPage__company-description {
    width: 70vw;
    padding: 1.5vw 5vw;
    text-align: center;
    background-color: rgb(229, 229, 229);
    animation: showDesc 1 1s linear;
}

@keyframes showDesc {
    0% {
        transform: translateX(-100%)
    }
    100% {
        transform: translateX(0)
    }
}

.aboutUsPage__employees-description-logo {
    background-color: black;
    background-image: url("../images/logo/logo.png");
    background-size: 20vw 7vw;
    background-repeat: no-repeat;
    background-position: center;
    width: 32vw;
    height: 12vw;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.aboutUsPage__employees {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-color: white;
}

.aboutUsPage__employees-photo {
    width: 34vw;
    height: 45vw;
    margin-left: 14vw;
}

.aboutUsPage__employees-description {
    position: absolute;
    text-align: justify;
    left: 45vw;
    padding: 5vw;
    background-color: rgb(229, 229, 229);
    top: 50%;
    transform: translate(100%, -50%);
    transition: 2s;
}

.aboutUsPage__employees-description-show {
    transform: translate(0, -50%);
}

.aboutUsPage__employees-description-heading {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2vw;
    border-bottom: 1px solid black;
}

.aboutUsPage__employees-description-position {
    font-size: 1.5vw;
    font-weight: 700;
}

.aboutUsPage__employees-description-normal {
    margin-top: 1vw;
    font-size: 1.0vw;
    line-height: 1.5vw;
}

@media (max-width: 1400px) {
    .aboutUsPage__employees-description-heading {
        font-size: 1.6rem;
    }

    .aboutUsPage__employees-description-normal {
        font-size: 1rem;
        line-height: 1.2rem;
    }

    .aboutUsPage__employees-description-position {
        font-size: 1.1rem;
    }
}

@media (max-width: 1200px) {
    .aboutUsPage__company-description {
        width: 100vw;
    }

    .aboutUsPage__employees-description-logo {
        opacity: 0;
    }

    .aboutUsPage__employees-photo {
        width: 26rem;
        height: 38rem;
        margin-left: 4rem;
    }
}

@media (max-width: 992px) {
    .aboutUsPage__employees {
        justify-content: space-around;
    }
    .aboutUsPage__employees-photo {
        margin-left: 0;
    }
    .aboutUsPage__employees-description {
        position: unset;
        transform: translate(150%, 0);
        margin: 0 4rem;
    }
    .aboutUsPage__employees-description-show {
        transform: translate(0, 0);
    }
}

@media (max-width: 768px) {
    .aboutUsPage__employees-description-heading {
        font-size: 1.4rem;
    }

    .aboutUsPage__employees-description-normal {
        font-size: .8rem;
        line-height: 1.1rem;
    }

    .aboutUsPage__employees-description {
        margin: 0 2rem;
    }
}

@media (max-width: 576px) {
    .aboutUsPage__employees-description-heading {
        font-size: 1.2rem;
    }

    .aboutUsPage__employees-description-normal {
        font-size: .7rem;
        line-height: 1rem;
    }
    .aboutUsPage__employees-description {
        margin: 0;
    }
}
