.footer{
    width: 100vw;
    height: 5vw;
    background-color: black;
    display: flex;
}

.footer__logo{
    flex-basis: 40%;
    background-image: url("../images/logo/logo.png");
    background-size: 40% 80%;
    background-position: center;
    background-repeat: no-repeat;
}

.footer__list{
    flex-basis: 50%;
}

.footer__media{
    display: flex;
    flex-basis: 10%;
}

.footer__list-media{
    flex-basis: 5.6%;
}

.navi__list-item-link-foo{
    height: 5vw;
}

@media (max-width: 1400px) {
    .footer__logo {
        background-size: 45% 60%;
    }
}

@media (max-width: 1200px) {
    .footer__logo {
        background-size: 50% 65%;
    }
}

@media (max-width: 992px) {
    .footer__logo {
        background-size: 55% 70%;
    }
}

@media (max-width: 768px) {
    .footer__logo {
        background-size: 60% 75%;
    }
}

@media (max-width: 576px) {
    .footer__logo {
        background-size: 70% 85%;
    }

}