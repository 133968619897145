.projectPage {
    position: relative;
    display: flex;
    flex-direction: column;
}

.projectPage__header {
    margin-top: 5vw;
    height: 15vw;
    width: 100%;
    overflow: hidden;
    background-color: white;
}

.projectPage__button {
    position: absolute;
    bottom: 1vw;
    left: 2.97vw;
    color: white;
    border-color: white;
    width: 6vw;
    line-height: 2.1vw;
    font-size: 1.0vw;
    margin: 0;
}

.projectPage__button:hover {
    background-color: white;
    color: black;
}

.projectPage__content {
    background-color: rgb(229,229,229);
}

.projectPage__content-title {
    text-transform: uppercase;
    padding: 2vw 0 .5vw 4vw;
    letter-spacing: .8vw;
    font-size: 2vw;
    position: relative;
}

.projectPage__content-title::after {
    background-color: black;
    position: absolute;
    left: 4vw;
    bottom: 0;
    content: '';
    height: .05vw;
    width: 25vw;
}

.projectPage__content-location {
    padding: 2vw 0 .5vw 4vw;
    font-size: 1.2vw;
}

.projectPage__content-area {
    padding-top: 1vw;
}

.projectPage__content-assum {
    padding-top: 1vw;
    padding-bottom: 2vw;
    text-align: justify;
    white-space: pre-line;
    padding-right: 3vw;
}

.projectPage__content-portfolio {
    display: flex;
    flex-direction: row;
}

.projectPage__content-portfolio-poster {
    flex-basis: 87%;
    max-width: 87%;
    height: 42vw;
    padding: 1vw;
}

.projectPage__content-portfolio-gallery {
    background-color: #181818;
    flex-basis: 13%;
    height: 42vw;
}

.projectPage__content-portfolio-gallery-box {
    height: 37.5vw;
    overflow-y: scroll;
}

#scrollbar::-webkit-scrollbar {
    width: .8vw;
    background-color: #181818;
}

#scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, .8);
    background-color: #555;
}

.projectPage__content-portfolio-gallery-poster {
    width: 10vw;
    height: 6vw;
    margin: 1.2vw auto;
    z-index: -10;
}

.projectPage__content-portfolio-gallery-poster:hover {
    box-shadow: 0 0 .8vw .2vw white;
    cursor: pointer;
}

.projectPage__content-portfolio-gallery-poster-active {
    box-shadow: 0 0 1vw .2vw white;
}

.projectPage__content-portfolio-poster-animation {
    animation: App-logo-spin 1 3s;
}

@keyframes App-logo-spin {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@media (max-width: 1400px) {
    .projectPage__header {
        height: 14rem;
    }

    .projectPage__content-title {
        letter-spacing: .6rem;
        font-size: 1.5rem;
        padding: 1.6rem 4rem .3rem 3rem;
    }

    .projectPage__content-location {
        padding: 1rem 3rem .5rem 3rem;
        font-size: 1rem;
    }

    .projectPage__button {
        width: 5.2rem;
        line-height: 1.9rem;
        font-size: .95rem;
    }

    .projectPage__content-assum {
        padding-bottom: 2rem;
    }
}

@media (max-width: 1200px) {
    .projectPage__header {
        height: 12rem;
    }

    .projectPage__content-portfolio-gallery {
        flex-basis: 20%;
    }

    .projectPage__content-portfolio-poster {
        flex-basis: 80%;
        max-width: 80%;
    }

    .projectPage__content-portfolio-gallery-poster {
        width: 15vw;
        height: 8vw;
    }

    .projectPage__button {
        left: 6vw;
        bottom: .5vw;
    }
}

@media (max-width: 992px) {
    .projectPage__header {
        height: 11rem;
    }

    .projectPage__content-portfolio {
        display: flex;
        flex-direction: column;
    }


    .projectPage__content-portfolio-gallery-box {
        height: 8rem;
        overflow-y: unset;
        white-space: nowrap;
        overflow-x: auto;
        width: 100vw;
    }

    .projectPage__content-portfolio-gallery-poster {
        display: inline-block;
        width: 10rem;
        height: 5rem;
        margin: .6rem;
    }

    .projectPage__content-portfolio-poster {
        flex-basis: 100%;
        max-width: 100%;
    }

    .projectPage__button {
        left: 1vw;
        bottom: 1vw;
        background-color: black;
    }
}

@media (max-width: 768px) {
    .projectPage__header {
        height: 9rem;
    }

    .projectPage__content-portfolio-gallery-box {
        height: 6.5rem;
    }

    .projectPage__content-portfolio-gallery-poster {
        width: 8rem;
        height: 4rem;
    }

    .projectPage__content-title {
        font-size: 1.4rem;
        padding: 1rem 2rem .2rem 2rem;
    }

    .projectPage__content-location {
        padding: 1rem 2rem .2rem 2rem;
        font-size: .9rem;
    }

    .projectPage__content-assum {
        padding-bottom: 1.5rem;
    }
}

@media (max-width: 576px) {
    .projectPage__header {
        height: 8rem;
    }
}