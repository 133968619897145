.SubPageHeader{
    position: relative;
    text-align: center;
    text-transform: uppercase;
    line-height: 4vw;
    transform: translateY(100%);
    margin: 2vw 0;

}

.SubPageHeader-show{
    animation: moveText 1s 1 linear;
    transform: translateY(0)
}

.SubPageHeader-top{
    font-size: 2.8vw;
}

.SubPageHeader-center{
    background-color: black;
    color: white;
    font-size: 2.5vw;
    font-weight: 900;
}

.SubPageHeader-bottom{
    font-size: 1.0vw;
    font-weight: 700;
}

@keyframes moveText {
    0%{
        transform: translateY(100%);
        opacity: 0;
    }
    50%{
        transform: translateY(50%);
        opacity: .1;
    } 100%{
        transform: translateY(0);
        opacity: 1;
    }
}

@media (max-width: 1400px) {
    .SubPageHeader{
        line-height: 3.6rem;
    }
    .SubPageHeader-top{
        font-size: 2.4rem;
    }

    .SubPageHeader-center{
        font-size: 2.2rem;
    }

    .SubPageHeader-bottom{
        font-size: 1rem;
    }
}

@media (max-width: 1200px) {
    .SubPageHeader{
        line-height: 3.2rem;
    }

    .SubPageHeader-top{
        font-size: 2rem;
    }

    .SubPageHeader-center{
        font-size: 2rem;
    }

    .SubPageHeader-bottom{
        font-size: .9rem;
    }
}

@media (max-width: 768px) {
    .SubPageHeader{
        line-height: 2.8rem;
    }

    .SubPageHeader-top{
        font-size: 1.8rem;
    }

    .SubPageHeader-center{
        font-size: 1.8rem;
    }

    .SubPageHeader-bottom{
        font-size: .8rem;
    }
}

@media (max-width: 576px) {
    .SubPageHeader{
        line-height: 2.6rem;
    }
    .SubPageHeader-top{
        font-size: 1.8em;
    }

    .SubPageHeader-center{
        font-size: 1.4rem;
    }

    .SubPageHeader-bottom{
        font-size: .8rem;
    }
}