.offerBlock {
    display: flex;
    flex-wrap: wrap;
}

.offerBlock__title {
    font-weight: 800;
    font-size: 2vw;
    text-align: center;
    color: black;
    margin: 3vw 13vw;
    border-bottom: 1px solid black;
}

.offerBlock__background{
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #181818;
    height: 40vw;
    transition: 1.5s;
}

.offerBlock__background:hover {
    background-size: 110%;
}

.offerBlock__aside {
    background-image: url("https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta+zdj%C4%99cie/of1.jpg");
    background-size: 100%;
    flex-basis: 50%;
}

.offerBlock__section {
    background-image: url("https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/oferta+zdj%C4%99cie/of2.jpg");
    background-size: 100%;
    flex-basis: 50%;
}

.offerBlock__box {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    padding: 3vw;
    opacity: 0;
    background-color: rgba(113, 118, 113, 0.5);
    transition: .6s;
}

.offerBlock__box:hover {
    opacity: 1;
}

.offerBlock__box-header {
    color: white;
    margin: 0 10vw 2vw 10vw;
    border-color: white;
}

.offerBlock__box-link {
    text-decoration: none;
    color: white;
}

.offerBlock__box-icon {
    display: inline-block;
    height: 10vw;
    width: 9vw;
    margin: .5vw;
    transition: .5s;
}

.offerBlock__box-icon:hover {
    color: black;
}

.offerBlock__box-icon-img {
    height: 4vw;
    width: 4vw;
}

.offerBlock__box-icon-text {
    display: table-cell;
    vertical-align: middle;
    font-size: 1vw;
    text-align: center;
    width: 10vw;
    height: 4vw;
    font-weight: 800;
}

.offerContent {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
}

.offerContent__header {
    margin-top: 5vw;
    height: 15vw;
    width: 100%;
    overflow: hidden;
    background-color: white;
}

.offerContent__text {
    text-align: justify;
    position: relative;
    margin-top: 3vw;
    background-color: rgb(229,229,229);
    width: 52%;
    padding: 5vw 5vw 7vw 8vw;
}

.offerContent__text-heading {
    margin-bottom: 2vw;
    text-align: left;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2vw;
    border-bottom: 1px solid black;
}

.offerContent__text-stage {
    margin-top: 2vw;
    font-size: 1.7vw;
    font-weight: 700;
    text-transform: uppercase;
}

.offerContent__text-bold {
    margin-top: 1vw;
    font-size: 1.3vw;
    font-weight: 500;
    text-transform: uppercase;
}

.offerContent__text-normal {
    margin-top: 1vw;
    font-size: 1.0vw;
    line-height: 1.5vw;
}

.offerContent__text-list {
    font-size: 1.0vw;
    margin-left: 1.1vw;
    line-height: 1.5vw;
}

.offerContent__aside {
    position: absolute;
    top: 20vw;
    right: 6vw;
    z-index: -1;
    width: 46vw;
    height: 36vw;
    object-fit: cover;
}

.offerContent__aside-stick {
    position: fixed;
    top: 7vw;
}

.offerContent__button {
    border: .15vw solid black;
    margin: 0 8vw 3vw 8vw;
    width: 7vw;
    font-size: 1.2vw;
    text-decoration: none;
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 2.4vw;
    position: relative;
    bottom: 4vw;
    transition: .5s;
}

.offerContent__button:hover {
    background-color: black;
    color: white;
}

@media only screen and (hover: none) and (pointer: coarse) {
    .offerBlock__box {
        opacity: 1;
        background-color: rgba(113, 118, 113, 0.5);
    }
}

@media (max-width: 1400px) {
    .offerBlock__title {
        font-size: 1.8rem;
    }

    .offerBlock__box-icon-img {
        height: 3.5rem;
        width: 3.5rem;
    }

    .offerBlock__box-icon-text {
        font-size: 1rem;
    }

    .offerContent__header {
        height: 13rem;
    }

    .offerContent__text-heading {
        margin-bottom: 1.2rem;
        font-size: 1.6rem;
    }

    .offerContent__text-normal {
        font-size: .95rem;
        line-height: 1.2rem;
    }

    .offerContent__text {
        padding: 2rem 6rem 6rem;
    }

    .offerContent__button {
        margin: 0 6rem 2rem;
        width: 6rem;
        font-size: 1.1rem;
        line-height: 2rem;
        bottom: 4rem;
    }

    .offerContent__aside {
        width: 36rem;
        height: 26rem;
    }

    .offerContent__text-bold {
        margin-top: 1rem;
        font-size: 1.1rem;
        font-weight: 500;
    }

    .offerContent__text-list {
        font-size: .95rem;
        margin-left: 1.2rem;
        line-height: 1.2rem;
    }
}

@media (max-width: 1200px) {
    .offerBlock__box {
        padding: 1rem;
    }

    .offerBlock__title {
        margin: 1rem 10rem;
    }

    .offerBlock__box-header {
        margin: 0 9rem 1rem 9rem;
    }

    .offerBlock__box-icon-img {
        height: 3rem;
        width: 3rem;
    }

    .offerBlock__box-icon-text {
        font-size: .9rem;
    }

    .offerContent__header {
        height: 11rem;
    }

    .offerContent__text-normal {
        font-size: .9rem;
    }

    .offerContent__text {
        padding: 2rem 5rem 6rem;
    }
    .offerContent__button {
        margin: 0 5rem 1rem;
    }

    .offerContent__aside {
        right: 0;
    }
}

@media (max-width: 992px) {
    .offerBlock__box {
        padding: .8rem;
    }

    .offerBlock__title {
        margin: .8rem 6rem;
    }

    .offerBlock__box-header {
        margin: 0 5.26rem 1rem 5.26rem;
    }

    .offerBlock__title {
        font-size: 1.5rem;
    }

    .offerBlock__box-icon-img {
        height: 2.4rem;
        width: 2.4rem;
    }

    .offerBlock__box-icon-text {
        font-size: .7rem;
    }

    .offerContent__text {
        margin-top: 3vw;
        width: 30rem;
        padding: 2rem 2rem 5rem;
        background-color: rgba(229,229,229,.6);
    }

    .offerContent__text-heading {
        margin-bottom: 1.1rem;
        font-size: 1.5rem;
    }

    .offerContent__text-normal {
        font-size: .8rem;
        line-height: 1.1rem;
    }

    .offerContent__button {
        margin: 0 2rem .5rem;
        width: 5.5rem;
        font-size: 1rem;
    }

    .offerContent__text-list {
        font-size: .8rem;
    }
}

@media (max-width: 768px) {
    .offerContent__header {
        height: 10rem;
    }

    .offerBlock__aside {
        flex-basis: 100%;
    }

    .offerBlock__section {
        flex-basis: 100%;
    }

    .offerBlock__title {
        margin: 0.8rem 12.8rem;
    }

    .offerBlock__box-header {
        margin: 0 12rem 1rem 12rem;
    }

    .offerBlock__box-icon {
        height: 5rem;
        width: 5rem;
        margin: .5vw;
    }
    .offerBlock__box-icon-img {
        height: 2.6rem;
        width: 2.6rem;
    }

    .offerBlock__box-icon-text {
        font-size: .8rem;
        width: 5rem;
    }

    .offerContent--mobile768{
        display: flex;
        flex-direction: column-reverse;
    }

    .offerContent__text{
        flex-basis: 100%;
        width: 100vw;
    }

    .offerContent__aside{
        position: relative;
        top: 0;
        right: 0;
        width: 100vw;
        height: 60vw;
    }
}

@media (max-width: 576px) {
    .offerContent__header {
        height: 9rem;
    }

    .offerBlock__box {
        padding: .2rem;
    }

    .offerBlock__title {
        margin: 0.2rem 3em;
    }

    .offerBlock__box-header {
        margin: 0 4.3rem 1rem 4.3rem;
    }

    .offerBlock__aside {
        height: 17rem
    }

    .offerBlock__section {
        height: 17rem
    }

    .offerBlock__box-icon {
        height: 4.5rem;
        width: 4.5rem;
        margin: .4vw;
    }
    .offerBlock__box-icon-img {
        height: 2.2rem;
        width: 2.2rem;
    }

    .offerBlock__box-icon-text {
        font-size: .7rem;
        width: 4.5rem;
    }
}