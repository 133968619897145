.navi {
    display: flex;
    position: fixed;
    width: 100vw;
    height: 7vw;
    z-index: 20;
    transition: 1s;
    background-color: rgba(0, 0, 0, 0);
}

.nav__scrolling {
    background-color: rgba(0, 0, 0, 1);
}

.navi__list-logo {
    flex-basis: 40%;
}

.navi__list-logo-link {
    display: block;
    height: 7vw;
    background-image: url("../images/logo/logo.png");
    background-size: 40% 55%;
    background-position: center;
    background-repeat: no-repeat;
}

.navi__list {
    position: relative;
    flex-basis: 50%;
}

.navi__list-items-box {
    display: flex;
}

.navi__list-item {
    display: flex;
    flex-basis: 20%;
}

.navi__list-item-link {
    flex-basis: 100%;
    line-height: 7vw;
    font-size: 1vw;
    text-align: center;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
}

.navi__list-item-link:hover {
    text-decoration: underline;
    text-underline-offset: .4vw;
}

.navi__list-item-link.active {
    text-decoration: underline;
    text-underline-offset: .4vw;
}

.navi__list-item-collapse-menu {
    position: absolute;
    width: 12%;
    height: 7vw;
    top: -7vw;
    left: 0;
}

.navi__list-item-collapse-menu-ico {
    position: relative;
    font-size: 1.7vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.navi__list-media {
    flex-basis: 10%;
    display: flex;
    color: white;
}

.navi__list-media-link {
    flex-basis: 33%;
}

.navi__list-media-ico {
    position: relative;
    font-size: 1.7vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

@media (max-width: 1400px) {
    .navi__list-logo-link {
        background-size: 45% 60%;
    }

    .navi__list-media-ico {
        font-size: 1.5rem
    }

    .navi__list-item-link {
        font-size: 1rem;
    }
}

@media (max-width: 1200px) {
    .navi__list-logo-link {
        background-size: 50% 65%;
    }
}

@media (max-width: 992px) {
    .navi__list-item-collapse-menu {
        top: 0;
    }

    .navi__list-items-box {
        flex-direction: column;
        margin-top: 7vw;
        background-color: #181818;
        opacity: .8;
        transform: translateY(-150%);
        transition: 1s
    }

    .navi__list-item-collapse-menu-ico {
        font-size: 1.4rem;
    }

    .navi__list-item-collapse-menu-show {
        transform: translateY(0);
        transition: 1s;
    }

    .navi__list-logo-link {
        background-size: 55% 70%;
    }

    .navi__list-media-ico {
        font-size: 1.4rem
    }
}

@media (max-width: 768px) {
    .navi__list-logo-link {
        background-size: 60% 75%;
    }

    .navi__list-media-ico {
        font-size: 1rem
    }

    .navi__list-item-collapse-menu-ico {
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .navi__list-logo-link {
        background-size: 70% 85%;
    }

    .navi__list-media-ico {
        font-size: 1rem
    }

    .navi__list-item-collapse-menu-ico {
        font-size: 1rem;
    }

    .navi__list-media {
        flex-basis: 15%;
    }
}
