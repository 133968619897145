.portfolioPage{
    position: relative;
    display: flex;
    flex-direction: column;
}

.portfolioPage__header{
    margin-top: 5vw;
    height: 15vw;
    width: 100%;
    overflow: hidden;
    background-color: white;
}

@media (max-width: 1400px) {
    .portfolioPage__header{
        height: 12rem;
    }
}

@media (max-width: 1200px) {
    .portfolioPage__header{
        height: 11rem;
    }
}

@media (max-width: 768px) {
    .portfolioPage__header{
        height: 8rem;
    }
}