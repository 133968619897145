.privacyPolicy{
    position: relative;
    display: flex;
    flex-direction: column;
}

.privacyPolicy__header {
    margin: 10vw auto auto auto;
    width: 100%;
    max-width: 80vw;
    background-color: white;
    text-align: justify;
}

.privacyPolicy__header ol{
    padding: 1vw 0 1vw 4vw;
}