.portfolioBlock {
    display: flex;
    flex-wrap: wrap;
}

.portfolioBlock__img {
    flex-basis: 33.33%;
    position: relative;
}

.portfolioBlock__img-poster{
    width: 100%;
    height: 100%;
}

.portfolioBlock__img-content{
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .8s;
}

.portfolioBlock__img-content:hover{
    opacity: 1;
}

.portfolioBlock__img-content-desc {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    color: white;
    text-transform: uppercase;
    text-align: center;
    font-size: 2vw;
    padding: 1vw;
    border: .1vw solid white;
}

.portfolioBlock__img-content-link {
    top: 80%;
    width: 30%;
    font-weight: bold;
    text-decoration: none;
    font-size: 1.2vw;
    padding: .5vw;
    animation: linkButtom infinite 2s linear;
}

@keyframes linkButtom {
    25% {
        border-top-color: transparent;
        border-bottom-color: transparent;
    }
    75% {
        border-left-color: transparent;
        border-right-color: transparent;
    }
}

@media only screen and (hover: none) and (pointer: coarse) {
    .portfolioBlock__img-content{
        background-color: rgba(0,0,0,0.2);
        opacity: 1;
    }
}

@media (max-width: 1400px) {
    .portfolioBlock__img-content-desc {
        font-size: 1.5rem;
        padding: .8rem;
    }

    .portfolioBlock__img-content-link {
        font-size: 1rem;
        padding: .6rem;
    }
}

@media (max-width: 1200px) {
    .portfolioBlock__img-content-desc {
        font-size: 1.2rem;
        padding: .6rem;
    }

    .portfolioBlock__img-content-link {
        font-size: .8rem;
        padding: .4rem;
    }
}

@media (max-width: 992px) {
    .portfolioBlock__img {
        flex-basis: 50%;
    }
}

@media (max-width: 768px) {
    .portfolioBlock__img-content-desc {
        font-size: 1rem;
        padding: .5rem;
    }

    .portfolioBlock__img-content-link {
        font-size: .7rem;
        padding: .4rem;
    }
}

@media (max-width: 576px) {
    .portfolioBlock__img {
        flex-basis: 100%;
    }
}
