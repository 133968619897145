.homePage {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

.homePage__aside {
    background-color: #181818;
    height: 100vh;
    flex-basis: 40%;
    z-index: 0;
}

.homePage__aside-textBox {
    position: absolute;
    width: 40%;
    top: 35vh;
    left: 0;
    opacity: 0;
    transition: 2s;
}

.homePage__aside-textBox-border{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0;
    width: 0;
    border-radius: 50%;
    border: 5px solid white;
    transition: 2s;
    z-index: -1;
}

.homePage__active .homePage__aside-textBox-border{
    height: 24vw;
    width: 24vw;
    border: 5px solid white;
}

.homePage__aside-textBox-text{
    font-size: 3vw;
    font-family: sans-serif;
    margin-left: 7vw;
    color: white;
    display: block;
    background-color: #181818;
    position: relative;
    left: -20%;
    transition: 2s;
}

.homePage__active .homePage__aside-textBox-text  {
    left: 0;
}

.homePage__aside-textBox-text:nth-of-type(1){
    font-size: 3vw;
    font-weight: 400;
    transition-delay: 0.2s;
}

.homePage__aside-textBox-text:nth-of-type(2){
    font-size: 3.5vw;
    font-weight: 800;
    transition-delay: 0.4s;
}

.homePage__aside-textBox-text:nth-of-type(3){
    font-size: 1.5vw;
    font-weight: 200;
    margin-right: 5vh;
    transition-delay: 0.6s;
}

.homePage__aside-dots {
    position: relative;
    top: 85vh;
    text-align: center;
}

.homePage__aside-dots-dot {
    background-color: #000;
    display: inline-block;
    margin: 0 2vw;
    width: .8vw;
    height: .8vw;
    border-radius: 50%;
    box-shadow: 0 0 0 .4vw white;
    cursor: pointer;
}

.homePage__aside-dots-dot.homePage__active {
    box-shadow: 0 0 0 .4vw white, 0 0 .4vw .6vw white;
}

.homePage__section{
    display: flex;
    position: relative;
    flex-basis: 60%;
}

.homePage__section-slider {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    object-fit: cover;
    transition: 2s;
    opacity: 0;
}

.homePage__active {
    opacity: 1;
}

.portfolioPage__more{
    display: block;
    border: .10vw solid black;
    width: 6vw;
    font-size: 1.2vw;
    text-decoration: none;
    color: black;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 2.4vw;
    position: relative;
    margin-top: 1vw;
    left: 50%;
    transform: translateX(-50%);
    transition: .5s;
}

.portfolioPage__more:hover{
    background-color: black;
    color: white;
}

@media (max-width: 1400px) {
    .homePage__aside-dots-dot {
        width: 1vw;
        height: 1vw;
        box-shadow: 0 0 0 .5vw white;
    }

    .homePage__aside-dots-dot.homePage__active {
        box-shadow: 0 0 0 .5vw white, 0 0 .5vw .7vw white;
    }
    .portfolioPage__more{
        width: 8rem;
        font-size: 1.2rem;
        line-height: 2.2rem;
        margin-top: .6rem;
    }
}

@media (max-width: 1200px) {
    .homePage__aside-dots-dot {
        width: 1.1vw;
        height: 1.1vw;
        box-shadow: 0 0 0 .6vw white;
    }

    .homePage__aside-dots-dot.homePage__active {
        box-shadow: 0 0 0 .6vw white, 0 0 .6vw .8vw white;
    }
    .portfolioPage__more{
        width: 6rem;
        font-size: 1rem;
        line-height: 2rem;
        margin-top: .6rem;
    }
}

@media (max-width: 992px) {
    .homePage__aside-dots-dot {
        width: 1.2vw;
        height: 1.2vw;
        box-shadow: 0 0 0 .8vw white;
    }

    .homePage__aside-dots-dot.homePage__active {
        box-shadow: 0 0 0 .8vw white, 0 0 .8vw 1vw white;
    }
}

@media (max-width: 768px) {
    .homePage__aside {
        position: absolute;
        width: 100%;
        z-index: 10;
        background-color: transparent;
    }

    .homePage__section{
        display: flex;
        position: relative;
        flex-basis: 100%;
    }

    .homePage__aside-textBox {
        width: 100%;
        padding: 0 25%;
    }

    .homePage__active .homePage__aside-textBox-border{
        height: 50vw;
        width: 50vw;
        background-color: rgba(0, 0, 0, .4);
    }

    .homePage__aside-textBox-text{
        color: white;
        background-color: transparent;
    }

    .homePage__aside-textBox-text:nth-of-type(1){
        font-size: 1.6rem;
    }

    .homePage__aside-textBox-text:nth-of-type(2){
        font-size: 1.4rem;
    }

    .homePage__aside-textBox-text:nth-of-type(3){
        font-size: 1rem;
    }

    .homePage__aside-dots-dot {
        margin: 0 3vw;
        width: 1.4vw;
        height: 1.4vw;
        box-shadow: 0 0 0 1vw white;
    }

    .homePage__aside-dots-dot.homePage__active {
        box-shadow: 0 0 0 1vw white, 0 0 1vw 1.4vw white;
    }

    .portfolioPage__more{
        width: 5.8rem;
        font-size: .9rem;
        line-height: 1.8rem;
        margin-top: .6rem;
    }
}

@media (max-width: 576px) {
    .homePage__aside-textBox {
        width: 100%;
        padding: 0 20%;
    }

    .homePage__aside-textBox-text:nth-of-type(1){
        font-size: 1.3rem;
    }

    .homePage__aside-textBox-text:nth-of-type(2){
        font-size: 1.1rem;
    }

    .homePage__aside-textBox-text:nth-of-type(3){
        font-size: .8rem;
    }
    .homePage__aside-dots-dot {
        width: 1.5vw;
        height: 1.5vw;
        box-shadow: 0 0 0 1.2vw white;
    }

    .homePage__aside-dots-dot.homePage__active {
        box-shadow: 0 0 0 1.2vw white, 0 0 1.2vw 1.6vw white;
    }

    .portfolioPage__more{
        width: 6rem;
        font-size: 1rem;
        line-height: 1.4rem;
        margin-top: .6rem;
    }

    .homePage__active .homePage__aside-textBox-border{
        height: 60vw;
        width: 60vw;
    }
}