.contactForm {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.contactForm__header {
    padding-top: 6vw;
    width: 100%;
    overflow: hidden;
    background-color: white;
}

.contactCard {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin: 3vw 0;
}

.contactCard__box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    line-height: 3vw;
    padding: 0 2vw;
}

.contactCard__box-informationPlace {
    flex-basis: 40%;
}

.contactCard__box-ico {
    font-size: 2vw;
}

.contactCard__box-desc {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;
    font-size: 1.4vw;
    letter-spacing: .5vw;
}

.contactCard__box-desc-bol {
    font-weight: 800;
    justify-content: center;
}

.contactCard__box-desc-contact {
    margin-top: 2vw;
    flex-basis: 50%;
    font-weight: 800;
    font-size: 1.6vw;
}

.contactCard__box-desc-contact--contact {
    flex-basis: 100%;
    text-align: left;
    padding: 0 5vw;
    margin: 0 0 20px 0;
}

.contactForm_box {
    width: 60%;
    text-align: center;
    border-right: 2px solid black;
}

.contactForm_box-header {
    text-transform: uppercase;
    font-size: 1.6vw;
    letter-spacing: .5vw;
    font-weight: 800;
    line-height: 5vw;
}

.contactForm_box-form {
    border: none;
    border-bottom: .1vw black solid;
    font-size: 1.2vw;
    margin: .8vw 0;
    width: 80%;
    padding-left: 1vw;
}

.contactForm_box-form-number::-webkit-outer-spin-button,
.contactForm_box-form-number::-webkit-inner-spin-button,
.contactForm_box-form-number[type=number] {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.contactForm_box-form-select {
    -webkit-appearance: none;
    -moz-appearance: none;
}

.contactForm_box-form-message {
    height: 8vw;
    resize: none;
}

.contactForm_box-ico {
    font-size: 1.4vw;
    margin-right: 1vw;
}

.contactForm_box-button {
    border-radius: 10%;
    margin: 1vw 0;
    left: 50%;
    transform: translateX(-50%);
    transition: .5s;
}

.contactForm-private_policy {
    font-size: .8vw;
    width: 80%;
    margin: 0 auto
}

.private_policy {
    font-size: 1.5vw;
    line-height: 2vw;
    font-weight: 800;
    color: white;
    width: 40%;
    padding: 5%;
    text-align: center;
    background-image: url('https://portfoliopiorkowski.s3.eu-west-3.amazonaws.com/kontakt/LISCIE.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

@media (max-width: 1400px) {
    .contactCard__box {
        line-height: 1.4rem;
    }

    .contactCard__box-desc {
        font-size: 1rem;
    }

    .contactForm_box-header {
        font-size: 1.4rem;
        line-height: 5rem;
    }

    .contactForm_box-ico {
        font-size: 1.4rem;
    }

    .contactForm_box-form {
        font-size: 1rem;
    }

    .contactCard__box-desc-contact {
        font-size: 1.2rem
    }

    .contactForm-private_policy {
        font-size: .7rem
    }

    .private_policy {
        font-size: 1.2rem;
        line-height: 1.5rem;
    }
}

@media (max-width: 1200px) {
    .private_policy {
        padding: 1.5rem;
    }
}

@media (max-width: 992px) {
    .contactForm_box-header {
        font-size: 1.2rem;
    }

    .contactForm_box-ico {
        font-size: 1.2rem;
    }

    .contactForm_box-form {
        font-size: .9rem;
    }

    .contactCard__box-desc-contact {
        font-size: 1.1rem
    }

    .contactForm-private_policy {
        font-size: .6rem
    }

    .private_policy {
        font-size: 1rem;
        line-height: 1.1rem;
    }
}

@media (max-width: 768px) {
    .contactForm_box {
        width: 100%;
        border-right: 0
    }

    .private_policy {
        width: 100%;
    }

    .contactCard__box {
        line-height: 1.2rem;
    }

    .contactCard__box-desc {
        font-size: .8rem;
    }
}

@media (max-width: 576px) {

}
