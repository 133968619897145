.remoteDesignPage {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.remoteDesignPage__header {
    margin-top: 8vw;
    height: 14vw;
    width: 100%;
    background-color: white;
}

@keyframes showDesc {
    0% {
        transform: translateX(-100%)
    }
    100% {
        transform: translateX(0)
    }
}

.remoteDesignPage_description {
    position: relative;
    text-align: justify;
    height: 20vw;
    overflow: hidden;
}

.remoteDesignPage_description-box {
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-wrap: wrap;
    width: 10vw;
}

.remoteDesignPage_description-box-ico {
    height: 6vw;
    width: 6vw;
    margin: 2vw 0
}

.remoteDesignPage_description-box-ico-active {
    box-shadow: 0 0 1vw .2vw #181818;
    border-radius: 50%;
}

.remoteDesignPage__interiors {
    width: 90vw;
    background-color: rgb(229, 229, 229);
    padding: 3vw;
    margin-left: 10vw;
    position: absolute;
    top: 0;
}

.remoteDesignPage__garden {
    width: 90vw;
    background-color: rgb(229, 229, 229);
    padding: 3vw;
    margin-left: 10vw;
    position: absolute;
    top: 0;
    left: 100%;
    transition: 2s;
}

.remoteDesignPage__garden-active {
    left: 0;
}

#scroll::-webkit-scrollbar {
    width: .8vw;
    background-color: rgb(229, 229, 229);
}

#scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, .8);
    background-color: #555;
}

.remoteDesignPage__text-heading {
    margin-bottom: 2vw;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 2vw;
    border-bottom: 1px solid black;
}

.remoteDesignPage__box-ico {
    margin-left: 10vw;
    width: 12vw;
    height: 12vw;
}

.remoteDesignPage__text-list {
    margin-top: 1vw;
    font-size: 1.0vw;
    line-height: 1.5vw;
    margin-left: 1.5vw;
    columns: 3;
}

.remoteDesignPage__text-sublist {
    margin-left: 1.5vw;
}

.remoteDesignPage__range-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.remoteDesignPage__range-description {
    flex-basis: 49%;
    margin: 10vw 0;
}

.remoteDesignPage__range-description-normal {
    flex-basis: 50%;
    padding: 1.5vw 7vw 1.5vw 2vw;
    text-align: center;
    font-size: 1.0vw;
    line-height: 1.5vw;
    background-color: rgb(229, 229, 229);
}

.remoteDesignPage__range-map {
    width: 50vw;
    transform: translateX(180%);
    transition: 2s;
}

.remoteDesignPage__range-map-show {
    transform: translateX(0%);
}

@media (max-width: 1400px) {
    .remoteDesignPage__header {
        margin-top: 5rem;
        height: 13rem;
    }

    .remoteDesignPage_description {
        height: 18rem;
    }

    .remoteDesignPage__text-heading {
        font-size: 1.6rem;
    }

    .remoteDesignPage__text-list {
        font-size: .9rem;
        line-height: 1.5vw;
        text-align: left;
    }

    .remoteDesignPage__box-ico {
        width: 9rem;
        height: 9rem;
    }

    .remoteDesignPage_description-box-ico {
        height: 6rem;
        width: 6rem;
    }

    .remoteDesignPage__range-description-normal {
        font-size: 1rem;
        line-height: 1.3rem;
    }
}

@media (max-width: 1200px) {
    .remoteDesignPage_description-box-ico {
        height: 5.5rem;
        width: 5.5rem;
    }

    .remoteDesignPage__text-list {
        font-size: .9rem;
        line-height: 1.1rem;
        column-gap: 70px;
    }

    .remoteDesignPage__box-ico {
        width: 8.5rem;
        height: 8.5rem;
        margin-left: 2rem;
    }
}

@media (max-width: 992px) {
    .remoteDesignPage__text-list {
        font-size: .9rem;
        line-height: 1.1rem;
        columns: 2;
        column-gap: 70px;
        margin-left: 1rem;
    }

    .remoteDesignPage_description-box {
        width: 15vw;
    }

    .remoteDesignPage__interiors {
        margin-left: 15vw;
        width: 85vw;
    }

    .remoteDesignPage__garden {
        margin-left: 15vw;
        width: 85vw;
    }

    .remoteDesignPage_description {
        height: 25rem;
    }

    .remoteDesignPage__box-ico {
        width: 10rem;
        height: 10rem;
        margin: 5rem 2rem;
    }

    .remoteDesignPage__range-box {
        flex-direction: column;
        align-items: center;
    }

    .remoteDesignPage__range-description {
        flex-basis: 100%;
        margin: 2rem 0;
    }

    .remoteDesignPage__range-map {
        width: 35rem;
    }
}

@media (max-width: 768px) {
    .remoteDesignPage_description {
        height: 26.5rem;
    }

    .remoteDesignPage__text-heading {
        font-size: 1.4rem;
    }

    .remoteDesignPage__text-list {
        font-size: .9rem;
        line-height: 1.1rem;
        columns: 2;
        column-gap: 20px;
    }

    .remoteDesignPage_description-box-ico {
        height: 5rem;
        width: 5rem;
    }
}

@media (max-width: 576px) {
    .remoteDesignPage_description {
        height: 34rem;
    }

    .remoteDesignPage__text-list {
        font-size: 1rem;
        columns: 1;
    }

    .remoteDesignPage_description-box-ico {
        height: 4rem;
        width: 4rem;
    }

    .remoteDesignPage__box-ico {
        width: 10rem;
        height: 10rem;
        margin: 0 0;
    }

    .remoteDesignPage__text-heading {
        font-size: 1.3rem;
        padding-right: 10px;;
    }

    .remoteDesignPage__range-description-normal {
        font-size: .9rem;
        line-height: 1.3rem;
    }
}